import React, { useState, useEffect } from 'react'
import { Card, CardTitle, CardImg, CardBody, CardFooter, Col, CardText } from 'reactstrap';
import { Link } from "react-router-dom";
import FavouriteIcon from './UI/Icons/FavouriteIcon'
import PlanCheckbox from './UI/PlanCheckbox'
import { postRequest, deleteRequest, baseUrl } from '../API/ApiHandler';

import placeholder from "../img/default_placeholder.png"
import './TipCard.css'

const TipCard = ({ tip, selected, setSelected, icon, setPlan, setSaved, setSaveStatus }) => {
  const [isSelected, setIsSelected] = useState(false)

  const toggleFavourite = async (ev) => {
    const input = { tip_id: tip.id }
    if (ev.target.getAttribute('data-active') === 'false') {
      postRequest("/userFavouriteTips", input)
      const addedFav = { id: tip.id }
      setSelected(fav => [...fav, addedFav])
      setIsSelected(true)
    } else {
      setIsSelected(false)
      setSelected(selected.filter(fav => fav.id !== tip.id))
      deleteRequest("/userFavouriteTips", input)
      deleteRequest("/userTipPlans", input)
    }
  }

  const toggleCheckbox = isChecked => {
    if (isChecked) {
      const addedPlan = { id: tip.id }
      setPlan(plan => [...plan, addedPlan])
      setIsSelected(true)
    } else {
      setIsSelected(false)
      setPlan(selected.filter(plan => plan.id !== tip.id))
    }
    setSaved(false)
    setSaveStatus('default')
  }

  useEffect(() => {
    if (selected) setIsSelected(selected.some(selection => tip.id === selection.id))
  }, [selected])

  //Get value of language from local storage
  let lang = localStorage.getItem("language")

  return (
    <Col xs="12" sm="6" md="6" lg="4" key={tip.id} className="all-tips-col">
      <Card className="card-style text-left">
        <Link to={
          {
            pathname: `/tip-details/${tip.name}`,
            state: [tip, isSelected]
          }
        }
        >
          <div className="card-img-container">
            <CardImg
              className="cardImg"
              top
              width="100%"
              alt={tip.name}
              src={tip.image ? `${baseUrl}/${tip.image}` : placeholder}
            />
          </div>
        </Link>
        <CardBody>
          <CardTitle tag="h4">{lang === "English" ? `${tip.name}` : `${tip.fr_name}`}</CardTitle>
          <div className="card-bottom">
            <CardText>
              {icon === "fav"
                ? <FavouriteIcon
                  toggleFav={toggleFavourite}
                  isFav={isSelected}
                />
                : <PlanCheckbox
                  toggleCheckbox={toggleCheckbox}
                  isChecked={isSelected}
                />
              }
            </CardText>
          </div>
        </CardBody>

        {/* {tip.traffic_level === 1 ? */}

        <CardFooter className="card-footer-green">
          <Link
            className="card-link"
            to={
              {
                pathname: `/tip-details/${tip.name}`,
                state: [tip, isSelected]
              }
            }>
            {lang === "English" ? "Read More" : "Lire la suite"}
          </Link>
        </CardFooter>

        {/* :
    
            tip.traffic_level === 2 ?
            
              <CardFooter className="card-footer-yellow">
                <Link 
                  className="card-link"
                  to={
                    {
                      pathname: `/tip-details/${tip.name}`,
                      state: [tip, isSelected]
                    }
                  }>
                  {lang === "English" ? "Read More" : "Lire la suite"}
                </Link>
              </CardFooter>
    
            :
    
              <CardFooter className="card-footer-red">
                <Link 
                  className="card-link"
                  to={
                    {
                      pathname: `/tip-details/${tip.name}`,
                      state: [tip, isSelected]
                    }
                  }>
                  {lang === "English" ? "Read More" : "Lire la suite"}
                </Link>
              </CardFooter>
            
            } */}

      </Card>
    </Col>
  )
}

export default TipCard
