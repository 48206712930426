import React, { useState, useEffect } from 'react'
import { Collapse } from 'reactstrap';
import { getRequest } from "../../API/ApiHandler"
import warning from '../../img/warning.png';

import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CircleControl from '../UI/Slider/CircleControl';

const QStiffness = ({ questionNo, setStiffnessOften, setFrStiffnessOften, setStiffnessLevel, stiffnessLevel, setSaved, setSaveStatus }) => {
  const [notAlways, setNotAlways] = useState()
  const [collapse, setCollapse] = useState(true)
  const [feel, setFeel] = useState("Never")
  const [frFeel, setFrFeel] = useState()
  const [level, setLevel] = useState(0)



  const GreenRadio = withStyles({
    root: {
      '&$checked': {
        color: '#10434F',
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const checked = ev => {
    let buttonList = document.querySelectorAll(".StepFour-div div");
    buttonList.forEach(button => {
      button.style.backgroundColor = "";
    });
    ev.target.style.backgroundColor = "#10434F";
    let feelVal = ev.target.getAttribute("value");
    switch (feelVal) {
      case "Never":
      case "Rarely":
      case "Sometimes":
      case "Often":
        setNotAlways(true)
        break;
      case "Not applicable":
      case "Always":
        setNotAlways(false)
        break;
      default:
        break;
    }

    setFeel(feelVal)
    setStiffnessOften(feelVal)

    let frFeelVal = ev.target.getAttribute("value");

    if (frFeelVal === "Never") {
      frFeelVal = "Jamais"
    } else if (frFeelVal === "Rarely") {
      frFeelVal = "Rarement"
    } else if (frFeelVal === "Sometimes") {
      frFeelVal = "Parfois"
    } else if (frFeelVal === "Often") {
      frFeelVal = "Souvent"
    } else if (frFeelVal === "Always") {
      frFeelVal = "Toujours"
    } else {
      frFeelVal = "Sans objet"
    }

    switch (frFeelVal) {
      case "Jamais":
      case "Rarement":
      case "Parfois":
      case "Souvent":
        setNotAlways(true)
        break;
      case "Sans objet":
      case "Toujours":
        setNotAlways(false)
        break;
      default:
        break;
    }

    setFrFeel(frFeelVal)
    setFrStiffnessOften(frFeelVal)

    setSaved(false)
    setSaveStatus('default')
  }


  const getValues = async () => {
    let { data: userStiffness } = await getRequest(`/stiffnessQuestion/user`)
    // console.log(userStiffness.stiffness_question)
    if (userStiffness) {
      setFeel(userStiffness.stiffness_question.feel)
      setFrFeel(userStiffness.stiffness_question.fr_feel)
      highlightSelected(userStiffness.stiffness_question.feel)
      setStiffnessLevel(userStiffness.stiffness_question.symptom_level)
    }
  }

  const highlightSelected = (pres) => {
    let prescribedBtns = document.querySelectorAll(".StepFour-div div")
    prescribedBtns.forEach(button => {
      if (button.getAttribute("value") === pres) {
        button.style.backgroundColor = "#10434F"
        if (pres !== "Not applicable" && pres !== 'Always') setNotAlways(true)
      }
    })
  }



  const setValue = (value, ...others) => {
    let forSlider = others[1]
    setStiffnessLevel(value)

    setSaved(false)
    setSaveStatus('default')
  }

  const handleCollapse = () => {
    setCollapse(!collapse)
    sessionStorage.setItem('stiffness-collapse', !collapse)
  }

  useEffect(() => {
    getValues()
    if (sessionStorage.getItem('stiffness-collapse')) {
      setCollapse(JSON.parse(sessionStorage.getItem('stiffness-collapse')))
    }
  }, [])

  //Get value of language from local storage
  let lang = localStorage.getItem("language")

  return (
    <React.Fragment>
      <div className="box-container-numbered collapsable">
        <div id="stiffness" onClick={handleCollapse}>
          <p className="numbered-subheading">
            {lang === "English"
              ? `${questionNo}. In the past 7 days, how often did you feel stiff?`
              : `${questionNo}. Au cours des 7 derniers jours, à quelle fréquence avez-vous ressenti des raideurs articulaires?`}
            <span className="collapsed-icon">
              {collapse
                ? "▲"
                : lang === "English"
                  ? "(Expand) ▼"
                  : "(Développer) ▼"}
            </span>
          </p>
        </div>
        <Collapse isOpen={collapse}>
          <div className="padding-class">
            <div className="Step-group-container">
              <p className="Step-subHeading">
                {lang === "English"
                  ? "How often do you feel stiffness?"
                  : "À quelle fréquence ressentez-vous des raideurs articulaires?"}
              </p>

              <div className="circle-group-container-Qstep5">
                <RadioGroup aria-label="position" value={feel || ""}>
                  <div className="circle-group-labels">
                    <h6>{lang === "English" ? "Never" : "Jamais"}</h6>
                    <GreenRadio
                      value={"Never" || null}
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels">
                    <h6>{lang === "English" ? "Rarely" : "Rarement"}</h6>
                    <GreenRadio
                      value="Rarely"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels">
                    <h6>
                      {lang === "English"
                        ? "Sometimes"
                        : "Parfois"}
                    </h6>
                    <GreenRadio
                      value="Sometimes"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels">
                    <h6>
                      {lang === "English"
                        ? "Often"
                        : "Souvent"}
                    </h6>
                    <GreenRadio
                      value="Often"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels">
                    <h6>{lang === "English" ? "Always" : "Toujours"}</h6>
                    <GreenRadio
                      value="Always"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>
                </RadioGroup>
              </div>

              <div className="circle-group-container-Qstep5-row">
                <RadioGroup row aria-label="position" value={feel || ""}>
                  <div className="circle-group-labels-row">
                    <h6>{lang === "English" ? "Never" : "Jamais"}</h6>
                    <GreenRadio
                      value={"Never" || null}
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels-row">
                    <h6>{lang === "English" ? "Rarely" : "Rarement"}</h6>
                    <GreenRadio
                      value="Rarely"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels-row">
                    <h6>
                      {lang === "English"
                        ? "Sometimes"
                        : "Parfois"}
                    </h6>
                    <GreenRadio
                      value="Sometimes"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels-row">
                    <h6>
                      {lang === "English"
                        ? "Often"
                        : "Souvent"}
                    </h6>
                    <GreenRadio
                      value="Often"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels-row">
                    <h6>{lang === "English" ? "Always" : "Toujours"}</h6>
                    <GreenRadio
                      value="Always"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>
                </RadioGroup>
              </div>
            </div>
            <div className="StepFour-treatment-h4-div">
              <div className="Step-group-container">
                <p className="Step-subHeading">
                  {lang === "English"
                    ? "How is your stiffness?"
                    : "Comment sont vos raideurs articulaires?"}
                </p>

                <CircleControl
                  setValue={setValue}
                  minLabel={
                    lang === "English" ? "No Stiffness" : "Aucune rigidité "
                  }
                  maxLabel={
                    lang === "English"
                      ? "Very Severe Stiffness"
                      : "Rigidité très sévère"
                  }
                  reversed={true}
                  value={stiffnessLevel}
                  forSlider="stiffness-level"
                ></CircleControl>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default QStiffness