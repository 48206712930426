import React, {useState, useEffect} from 'react'
import {getRequest} from "../../API/ApiHandler"
import TipButton from './TipButton'


const Tips = ({selectedTips, setSelectedTips, setSaved, setSaveStatus}) => {
  const [tips, setTips] = useState([])

  //Get value of language from local storage
  let lang = localStorage.getItem("language")

  const getTips = async() => {
    await getSelectedTips()
    let data = await getRequest("/tips")
    setTips(data)
  }

  const getSelectedTips = async() => {
    let tips = await getRequest(`/userTips/user`)
    if (tips) {
      let {data} = tips
      setSelectedTips(data)
    }
  }

  useEffect(() => {
    getTips()
  },[])

  const tipsContent = (tips.length > 0) && 
  tips.map(tip =>
    <TipButton
      key={tip.id}
      tip={tip}
      selectedTips = {selectedTips}
      setSelectedTips = {setSelectedTips}
      setSaved = {setSaved}
      setSaveStatus = {setSaveStatus}
    />
    )

  return (
    <div className="StepTwo-div other">
      <h4 className="Step-subHeading">
        
        {lang === "English" ? 
            "Which types of activities do you wish to participate in more fully?" :
            "À quels types d'activités souhaitez-vous participer plus pleinement?"}
        </h4>
      <div>
        {tipsContent.length 
          ? tipsContent 
          : <h4 style={{padding: '1.5rem'}}>{lang === "English" ? 
            "There are currently no items listed under this classification" : 
            "Il n'y a actuellement aucun article répertorié sous cette classification"}</h4>}
      </div>
    </div>
  )
}

export default Tips