import React, { useState, useEffect } from 'react'
import { Collapse } from 'reactstrap';
import { getRequest } from "../../API/ApiHandler"
import warning from '../../img/warning.png';

import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CircleControl from '../UI/Slider/CircleControl';
import './ButtonSpacing.css'

const QFatigue = ({ questionNo, setFatigueLevel, fatigueLevel, setFatigueFrFeel, setFatigueFeel, setSaved, setSaveStatus }) => {
  const [notAlways, setNotAlways] = useState()
  const [collapse, setCollapse] = useState(true)
  const [feel, setFeel] = useState("Never")

  const [frPrescribed, setFrFeel] = useState("Never")
  const GreenRadio = withStyles({
    root: {
      '&$checked': {
        color: '#10434F',
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const checked = ev => {
    let buttonList = document.querySelectorAll(".StepFour-div div");
    buttonList.forEach(button => {
      button.style.backgroundColor = "";
    });
    ev.target.style.backgroundColor = "#10434F";
    let medVal = ev.target.getAttribute("value");
    switch (medVal) {
      case "Never":
      case "Rarely":
      case "Sometimes":
      case "Often":
        setNotAlways(true)
        break;
      case "Not applicable":
      case "Always":
        setNotAlways(false)
        break;
      default:
        break;
    }

    setFeel(medVal)
    setFatigueFeel(medVal)

    let frMedVal = ev.target.getAttribute("value");

    if (frMedVal == "Never") {
      frMedVal = "Jamais"
    } else if (frMedVal == "Rarely") {
      frMedVal = "Rarement"
    } else if (frMedVal == "Sometimes") {
      frMedVal = "Parfois"
    } else if (frMedVal == "Often") {
      frMedVal = "Souvent"
    } else if (frMedVal == "Always") {
      frMedVal = "Toujours"
    } else {
      frMedVal = "Sans objet"
    }

    switch (frMedVal) {
      case "Jamais":
      case "Rarement":
      case "Parfois":
      case "Souvent":
        setNotAlways(true)
        break;
      case "Sans objet":
      case "Toujours":
        setNotAlways(false)
        break;
      default:
        break;
    }

    setFrFeel(frMedVal)
    setFatigueFrFeel(frMedVal)

    setSaved(false)
    setSaveStatus('default')
  }


  const getValues = async () => {

    let { data: fatigueQuestions } = await getRequest(`/fatigueQuestions/user`)
    if (fatigueQuestions.fatigue_question) {
      setFeel(fatigueQuestions.fatigue_question.feel)
      setFrFeel(fatigueQuestions.fatigue_question.fr_feel)
      setFatigueLevel(fatigueQuestions.fatigue_question.symptom_level)
      highlightSelected(fatigueQuestions.fatigue_question.feel, fatigueQuestions.fatigueQuestions.symptom_level)
    }

  }

  const highlightSelected = (pres, other) => {
    let prescribedBtns = document.querySelectorAll(".StepFour-div div")
    prescribedBtns.forEach(button => {
      if (button.getAttribute("value") === pres) {
        button.style.backgroundColor = "#10434F"
        if (pres !== "Not applicable" && pres !== 'Always') setNotAlways(true)
      }
    })

    let otherBtns = document.querySelectorAll(".StepFour-div-other div")
    otherBtns.forEach(button => {
      if (button.getAttribute("value") === other) {
        button.style.backgroundColor = "#10434F"
      }
    })
  }

  const handleCollapse = () => {
    setCollapse(!collapse)
    sessionStorage.setItem('howoften-collapse', !collapse)
  }

  useEffect(() => {
    getValues()
    if (sessionStorage.getItem('howoften-collapse')) {
      setCollapse(JSON.parse(sessionStorage.getItem('howoften-collapse')))
    }
  }, [])

  //Get value of language from local storage
  let lang = localStorage.getItem("language")

  return (
    <React.Fragment>
      <div className="box-container-numbered collapsable">
        <div id="fifth" onClick={handleCollapse}>
          <p className="numbered-subheading">
            {lang === "English"
              ? `${questionNo}. In the past 7 days, how often did you feel tired?`
              : `${questionNo}. Au cours des 7 derniers jours, à quelle fréquence vous êtes-vous senti fatigué?`}
            <span className="collapsed-icon">
              {collapse
                ? "▲"
                : lang === "English"
                  ? "(Expand) ▼"
                  : "(Développer) ▼"}
            </span>
          </p>
        </div>
        <Collapse isOpen={collapse}>
          <div className="padding-class">
            <div className="Step-group-container">
              <p className="Step-subHeading">
                {lang === "English"
                  ? "How often do you feel fatigue?"
                  : "À quelle fréquence ressentez-vous de la fatigue?"}
              </p>

              <div className="circle-group-container-Qstep5">
                <RadioGroup aria-label="position" value={feel || ""}>
                  <div className="circle-group-labels">
                    <h6>{lang === "English" ? "Never" : "Jamais"}</h6>
                    <GreenRadio
                      value={"Never" || null}
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels">
                    <h6>{lang === "English" ? "Rarely" : "Rarement"}</h6>
                    <GreenRadio
                      value="Rarely"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels">
                    <h6>
                      {lang === "English"
                        ? "Sometimes"
                        : "Parfois"}
                    </h6>
                    <GreenRadio
                      value="Sometimes"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels">
                    <h6>
                      {lang === "English"
                        ? "Often"
                        : "Souvent"}
                    </h6>
                    <GreenRadio
                      value="Often"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels">
                    <h6>{lang === "English" ? "Always" : "Toujours"}</h6>
                    <GreenRadio
                      value="Always"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>
                </RadioGroup>
              </div>

              <div className="circle-group-container-Qstep5-row">
                <RadioGroup row aria-label="position" value={feel || ""}>
                  <div className="circle-group-labels-row">
                    <h6>{lang === "English" ? "Never" : "Jamais"}</h6>
                    <GreenRadio
                      value={"Never" || null}
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels-row">
                    <h6>{lang === "English" ? "Rarely" : "Rarement"}</h6>
                    <GreenRadio
                      value="Rarely"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels-row">
                    <h6>
                      {lang === "English"
                        ? "Sometimes"
                        : "Parfois"}
                    </h6>
                    <GreenRadio
                      value="Sometimes"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels-row">
                    <h6>
                      {lang === "English"
                        ? "Often"
                        : "Souvent"}
                    </h6>
                    <GreenRadio
                      value="Often"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>

                  <div className="circle-group-labels-row">
                    <h6>{lang === "English" ? "Always" : "Toujours"}</h6>
                    <GreenRadio
                      value="Always"
                      onClick={checked}
                      style={GreenRadio.root}
                    />
                  </div>
                </RadioGroup>
              </div>
            </div>

            <div className="StepFour-treatment-h4-div">
              <div className="Step-group-container">
                <p className="Step-subHeading">
                  {lang === "English"
                    ? "How is your fatigue?"
                    : "Comment est votre fatigue?"}
                </p>

                <Collapse isOpen={collapse}>
                  <CircleControl
                    setValue={setFatigueLevel}
                    minLabel={
                      lang === "English" ? "No Fatigue" : "Pas de Fatigue"
                    }
                    maxLabel={
                      lang === "English"
                        ? "Very Severe Fatigue"
                        : "Fatigue très sévère"
                    }
                    reversed={true}
                    value={fatigueLevel}
                    forSlider="pain-level"
                  ></CircleControl>
                </Collapse>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default QFatigue