/**
 * @file
 * Component for step 3 "Review & select treatment options"
 * Contains the "All Treatments" tab section and @see CategoryTreatments component
 * Fetches treatments classifications
 * @author <akel.ms93@gmail.com>
 */

import React, {useState, useEffect} from 'react';
import CategoryTreatments from './CategoryTreatments'
import CategoryTips from './CategoryTips'
import {getRequest} from "../../API/ApiHandler"

const AllTreatments = ({ favs, setFavs, treatments, favTips, setFavTips }) => {
  const [classifications, setClassifications] = useState([])
  const [tips,setTips] = useState([])

  const getClassifications = async() => {
    let data = await getRequest("/treatmentClassifications")
    if(data) setClassifications(data)
  }

  //retrieve all the tips from database
  const getTips = async () => {
    let data = await getRequest("/tips")
    if (data) setTips(data)
  }

  useEffect(() => {
    getTips()
    if(treatments && favs) getClassifications()
  },[treatments, favs])

  //Get value of language from local storage
  let lang = localStorage.getItem("language")

  const classificationsList = classifications.length &&
    classifications.map((classification) => (
      <CategoryTreatments
        key={classification.id}
        classification={classification}
        treatments={treatments}
        favs={favs}
        setFavs={setFavs}
      />
    ))
  
  const tipList = tips.length &&
    tips.map((tip) => (
      <CategoryTips
        key={tip.id}
        tipClassification={tip}
        tips={tips}
        favTips={favTips}
        setFavTips={setFavTips}
      />
    ))

  return (
    <React.Fragment>
      <div className="notice">
        <h5>
          {lang === "English" ? 
          "Below is a list of the categories of treatments and tips that can be used for your symptoms. If any of the treatments or tips appeal to you, you can save them to your favourites." : 
          "Vous trouverez ci-dessous une liste des catégories de traitements et des conseils qui peuvent être utilisés pour vos symptômes. Si l'un des traitements ou conseils vous intéresse, vous pouvez les enregistrer dans vos favoris."}  
        </h5>
      </div>
      <div className="review-content all-treatments-review">
        {classificationsList}
        {tipList}
      </div>
      <div className="notice">
        <h5>
        {lang === "English" ? 
          "You can click the “My Favourites” tab to review your favourites" : 
          "Vous pouvez cliquer sur l’onglet “Mes favoris” pour consulter vos favoris."}
        </h5>
      </div>
    </React.Fragment>
  )
}
export default AllTreatments
