import React, { useState, useEffect } from 'react'
import { Collapse, Row } from 'reactstrap';

import TipCard from '../TipCard'

const CategoryTips = ({ tipClassification, setFavTips, favTips, tips }) => {
    const [classTips, setClassTips] = useState([])
    const [isCollapsed, setIsCollapsed] = useState(true)

    useEffect(() => {
        setClassTips(tips.filter(tip => tip === tipClassification))
    }, [])

    //handle collapse
    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed)
        sessionStorage.setItem(`${tipClassification.name}-collapse`, !isCollapsed)
    }

    //Get value of language from local storage
    let lang = localStorage.getItem("language")

    // Tip card list
    const tipCards = classTips &&
        classTips.map(tip =>
            <TipCard
                key={tip.id} 
                tip={tip}
                selected={favTips}
                setSelected={setFavTips}
                icon="fav"
            />
        )

    return (
        <div className="box-container-numbered">
            <div className="AllTreatments_treatment_div" onClick={handleCollapse}>
                <p>{lang === "English" ? tipClassification.name : tipClassification.fr_name}</p>
                <span className="collapsed-icon">{isCollapsed ? "+" : "-"}</span>
            </div>
            <Collapse isOpen={!isCollapsed} style={{ marginBottom: '2rem' }}>
                <Row className="padding-class">
                    {tipCards}
                </Row>
            </Collapse>
        </div>
    )
}

export default CategoryTips