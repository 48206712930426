import React, {Component} from 'react'
import {NavLink} from 'react-router-dom';
import "./TabNavReview.css"
export default class TabNavReview extends Component{

render(){

    //Get value of language from local storage
    let lang = localStorage.getItem("language")

    return (
      <div className="reviewDiv-nav">
        {this.props.current.match.url ===
          "/review-and-select/recommendations" && (
          <React.Fragment>
            <NavLink
              to="/review-and-select/recommendations"
              className="review-active"
            >
              <h3 class="orangeHeading">
                <strong>
                  {lang === "English"
                    ? "Suggested treatment and tips"
                    : "Traitements et conseils suggérés"}
                </strong>
              </h3>
            </NavLink>
            <NavLink to="/review-and-select/allTreatments">
              <h3 class="orangeHeading">
                {lang === "English"
                  ? "All treatments and tips"
                  : "Tous les traitements et conseils"}
              </h3>
            </NavLink>
            <NavLink to="/review-and-select/favorites">
              <h3 class="orangeHeading">
                {lang === "English" ? "My favourites" : "Mes favoris"}
              </h3>
            </NavLink>
          </React.Fragment>
        )}
        {this.props.current.match.url ===
          "/review-and-select/allTreatments" && (
          <React.Fragment>
            <NavLink to="/review-and-select/recommendations">
              <h3 class="orangeHeading">
                {lang === "English"
                  ? "Suggested treatment and tips"
                  : "Traitements et conseils suggérés"}
              </h3>
            </NavLink>
            <NavLink
              to="/review-and-select/allTreatments"
              className="review-active"
            >
              <h3 class="orangeHeading">
                <strong>
                  {lang === "English"
                    ? "All treatments and tips"
                    : "Tous les traitements et conseils"}
                </strong>
              </h3>
            </NavLink>
            <NavLink to="/review-and-select/favorites">
              <h3 class="orangeHeading">
                {lang === "English" ? "My favourites" : "Mes favoris"}
              </h3>
            </NavLink>
          </React.Fragment>
        )}
        {this.props.current.match.url === "/review-and-select/favorites" && (
          <React.Fragment>
            <NavLink to="/review-and-select/recommendations">
              <h3 class="orangeHeading">
                {lang === "English"
                  ? "Suggested treatment and tips"
                  : "Traitements et conseils suggérés"}
              </h3>
            </NavLink>
            <NavLink to="/review-and-select/allTreatments">
              <h3 class="orangeHeading">
                {lang === "English"
                  ? "All treatments and tips"
                  : "Tous les traitements et conseils"}
              </h3>
            </NavLink>
            <NavLink
              to="/review-and-select/favorites"
              className="review-active"
            >
              <h3 class="orangeHeading">
                <strong>
                  {lang === "English" ? "My favourites" : "Mes favoris"}
                </strong>
              </h3>
            </NavLink>
          </React.Fragment>
        )}
      </div>
    );}
}

