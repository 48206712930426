import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import "./Header.css"
import TranslateButton from "../../components/UI/TranslateButton";
import logo_en from '../../img/logo_en.png';
import logo_fr from '../../img/logo_fr.png';

//Get value of language from local storage
let lang = localStorage.getItem("language")

export default class Header extends Component{
  handleClick(ev, link){
    ev.preventDefault()
    this.props.handleNav(link)
    this.props.setNavTo(link)
  }

  render(){
    return (
      <div>
        {(this.props.current.match.path === "/home" ||
          this.props.current.match.path === "/notice" ) && (
          <div className="home-header">
            <div className="home-logo-header">
              <NavLink
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexGrow: "1",
                }}
                to="/home"
              >
                <img
                  className="logo-home"
                  alt="logo"
                  src={lang === "English" ? logo_en : logo_fr}
                />
              </NavLink>
              <div>
                <div className="home-logo-header-translation">
                  {" "}
                  <TranslateButton />{" "}
                </div>
              </div>
            </div>
            <div className="sub-header">
              <div className="home-subheader-content">
                <h3>
                  {lang === "English"
                    ? "Managing Symptoms in Juvenile Idiopathic Arthritis"
                    : "Prendre en charge les symptômes associés à l'arthrite juvénile idiopathique"}
                </h3>
              </div>
            </div>
          </div>
        )}
        <div className="header">
          {this.props.current.match.path !== "/home" &&
            this.props.current.match.path !== "/notice" && (
              <div className={"header-container"}>
                <div className="main-container">
                  <div>
                    <div className="tab-nav-header-translation">
                      {" "}
                      <TranslateButton />{" "}
                    </div>
                  </div>
                  <NavLink to="/home">
                    <img
                      className="logo-link"
                      alt="logo"
                      src={lang === "English" ? logo_en : logo_fr}
                    />
                  </NavLink>
                </div>
                <ul className="tab-nav-header">
                  <div className="tab-steps">
                    {this.props.current.match.path === "/questionnaire" ? (
                      <NavLink
                        className="tab-nav-header-item tab-nav-header-navlink"
                        to="/questionnaire"
                        onClick={(ev) => this.handleClick(ev, "/preparation")}
                      >
                        <p>{lang === "English" ? "Symptoms" : "Symptômes"}</p>
                        <div className="tab-navlink-div">
                          <h2>1</h2>
                        </div>
                      </NavLink>
                    ) : this.props.current.match.path === "/preparation" ? (
                      <NavLink
                        className="tab-nav-header-item tab-nav-header-navlink"
                        to="/preparation"
                        onClick={(ev) => this.handleClick(ev, "/preparation")}
                      >
                        <p>{lang === "English" ? "Symptoms" : "Symptômes"}</p>
                        <div className="tab-navlink-div">
                          <h2>1</h2>
                        </div>
                      </NavLink>
                    ) : (
                      <NavLink
                        className="tab-nav-header-item tab-nav-header-navlink"
                        to="/questionnaire2"
                        onClick={(ev) => this.handleClick(ev, "/preparation")}
                      >
                        <p>{lang === "English" ? "Symptoms" : "Symptômes"}</p>
                        <div className="tab-navlink-div">
                          <h2>1</h2>
                        </div>
                      </NavLink>
                    )}

                    <NavLink
                      className={
                        lang === "English"
                          ? "tab-nav-header-item tab-nav-header-navlink"
                          : "tab-nav-header-item tab-nav-header-navlink tab-nav-header-navlink-fr-2"
                      }
                      to="/whats-important-to-you"
                      onClick={(ev) =>
                        this.handleClick(ev, "/whats-important-to-you")
                      }
                    >
                      <p>
                        {lang === "English"
                          ? "What's important"
                          : "Ce qui est important pour vous"}
                      </p>
                      <div
                        className={
                          lang === "English"
                            ? "tab-navlink-div"
                            : "tab-navlink-div-fr"
                        }
                      >
                        <h2>2</h2>
                      </div>
                    </NavLink>

                    {this.props.current.match.path ===
                    "/treatment-details/:treatment" ? (
                      <NavLink
                        className="tab-nav-header-item tab-nav-header-navlink"
                        to="/treatment-details"
                        onClick={(ev) =>
                          this.handleClick(ev, "/review-and-select")
                        }
                      >
                        <p>
                          {lang === "English"
                            ? "Review & select"
                            : "Réviser et sélectionner"}
                        </p>
                        <div className="tab-navlink-div">
                          <h2>3</h2>
                        </div>
                      </NavLink>
                    ) : this.props.current.match.path ===
                      "/tip-details/:tip" ? (
                      <NavLink
                        className="tab-nav-header-item tab-nav-header-navlink"
                        to="/tip-details"
                        onClick={(ev) =>
                          this.handleClick(ev, "/review-and-select")
                        }
                      >
                        <p>
                          {lang === "English"
                            ? "Review & select"
                            : "Réviser et sélectionner"}
                        </p>
                        <div className="tab-navlink-div">
                          <h2>3</h2>
                        </div>
                      </NavLink>
                    ) : (
                      <NavLink
                        className="tab-nav-header-item tab-nav-header-navlink"
                        to="/review-and-select"
                        onClick={(ev) =>
                          this.handleClick(ev, "/review-and-select")
                        }
                      >
                        <p>
                          {lang === "English"
                            ? "Review & select"
                            : "Réviser et sélectionner"}
                        </p>
                        <div className="tab-navlink-div">
                          <h2>3</h2>
                        </div>
                      </NavLink>
                    )}

                    <NavLink
                      className="tab-nav-header-item tab-nav-header-navlink"
                      to="/make-your-plan"
                      onClick={(ev) => this.handleClick(ev, "/make-your-plan")}
                    >
                      <p>{lang === "English" ? "Plan" : "Plan"}</p>
                      <div className="tab-navlink-div">
                        <h2>4</h2>
                      </div>
                    </NavLink>
                    <NavLink
                      className="tab-nav-header-item tab-nav-header-navlink"
                      to="/summary"
                      onClick={(ev) => this.handleClick(ev, "/summary")}
                    >
                      <p>{lang === "English" ? "Summary" : "Résumé"}</p>
                      <div className="tab-navlink-div">
                        <h2>5</h2>
                      </div>
                    </NavLink>
                  </div>
                </ul>
              </div>
            )}
        </div>
      </div>
    );
  }
}
