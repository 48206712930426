import React, {useState, useEffect} from "react";
import Header from "../../components/Home/Header";
import Footer from "../../components/Footer";
import warning from '../../img/warning.png';
import './Preparation.css'
import IdleComponent from "../../components/IdleComponent";
import Symptoms from "../../components/Preparation/Symptoms";
import Tips from "../../components/Preparation/Tips";
import {updateLogStepOne} from "../../components/HandleUserLog"
import {postRequest, getRequest} from "../../API/ApiHandler"
import NavigationButton from "../../components/UI/Buttons/NavigationButton";
import Subheader from "../../components/UI/Subheader/Subheader";



const Decision = props => {
    
    const [selectedSyms, setSelectedSyms] = useState([])
    const [selectedTips, setSelectedTips] = useState([])
    const [displayNavDialog, setDisplayNavDialog] = useState(false)
    const [navTo, setNavTo] = useState('')
    const [navBy, setNavBy] = useState('')
    const [saved, setSaved] = useState(true)
    const [didSelect, setDidSelect] = useState(false)

    const [saveStatus, setSaveStatus] = useState('default')

    // const getSelectedSyms = async() => {
    //   let syms = await getRequest(`/userSymptoms/user`)
    //   if (syms) {
    //     let {data} = syms
    //     setSelectedSyms(data)
    //     console.log(`Select ${data}`)
    //   }
    // }

    // const getSelectedTips = async() => {
    //   let tips = await getRequest(`/userTips/user`)
    //   if (tips) {
    //     let {data} = tips
    //     setSelectedTips(data)
    //   }
    // }

    //Get value of language from local storage
    let lang = localStorage.getItem("language")

    // Save values when save button is clicked
    const saveHandler = async() => {
      await Promise.all([
        saveSelectedSymptoms(),
        saveSelectedTips(),
      ]).then(async (data) => {
        let failed = data.indexOf() !== -1
        if (!failed) {
          // await handleRecommendations()
          updateLog()
          setSaved(true)
          setSaveStatus('success')
          props.history.push(`/questionnaire`)
        } else {
          setSaveStatus('default')
        }
      })
    }


    const updateLog = () => {
        // List of Selected Symptoms
        const symptoms = selectedSyms
          .map(sym => {
            let symName = {name: sym.name}
            return symName
          })
    
        // List of Selected Tips
        const tips = selectedTips
        .map(tip => {
          let tipName = {name: tip.name}
          return tipName
        })
          
    
        // const data = {
        //     selectedSyms: symptoms,
        //     selectedTips: tips
        // }
        // updateLogStepOne(data)
    }

    const saveSelectedSymptoms = () => {
        const input = {selectedSyms: selectedSyms}
        return postRequest('/userSymptoms', input, setSaveStatus)
    }

    const saveSelectedTips = () => {
        const input = {selectedTips: selectedTips}
        return postRequest('/userTips', input, setSaveStatus)
    }

    const handleStepperNav = to => {
        if(saved) {
            props.history.push(to)
        } else {
            setNavBy('stepper')
            setDisplayNavDialog(true)
        }
    }
    
    return (
        <div className="wrapper">
            <IdleComponent history={props.history} saveHandler={saveHandler} status={saveStatus}/>
            <div className='page-header'>
                <Header current={props} handleNav={handleStepperNav} setNavTo={setNavTo}/>
            </div>
            <div className="page-subheader">
                <Subheader title={lang === "English" ? "Symptoms" : "Symptômes"}
                />
            </div>

            <div className="body-container">
                <div class = "box-container-numbered">
                    <div className="StepTwo-div other">
                        <p className="numbered-subheading">
                        {lang === "English" ? "Please choose the symptoms you wish to manage and the types of activities you wish to participate in more fully" : "Veuillez choisir les symptômes que vous souhaitez gérer et les types d'activités auxquelles vous souhaitez participer plus pleinement"}
                        </p>

                        <Symptoms
                            selectedSyms = {selectedSyms} 
                            setSelectedSyms = {setSelectedSyms}
                            setSaved = {setSaved}
                            setSaveStatus={setSaveStatus}
                        />

                        <Tips
                            selectedTips = {selectedTips} 
                            setSelectedTips = {setSelectedTips}
                            setSaved = {setSaved}
                            setSaveStatus={setSaveStatus}
                        />
                        <br></br><br></br>
                            <h6 class="center">
                                {lang === "English" ?
                                    "The choices you make on this page will change the questions that will be shown on the next page and will decide which treatments and tips will be shown to you in Step 3." :
                                    "Les choix que vous faites sur cette page modifieront les questions qui seront affichées sur la page suivante et décideront quels traitements et conseils vous seront présentés à l'étape 3."}
                            </h6>

                    </div>

                </div>



            </div>


            <NavigationButton className="next-btn" handleNavigation={saveHandler}
            btnText = {lang === "English" ? "Next" : "Suivant(e)"}
            />    


            <Footer />
        </div>

    );
};

export default Decision;
