import React, {useEffect, useState} from 'react'
import {Collapse} from 'reactstrap';
import StudyDetails from './StudyDetails';

const Subcategory = ({subcategory, subNo}) => {
  const [collapse, setCollapse] = useState(false)

  //Get value of language from local storage
  let lang = localStorage.getItem("language")
  
  useEffect(() => {
    if (subNo === 1 ) setCollapse(true)
  }, [subNo])

  return (
    <React.Fragment>
      {subcategory.name ?
        <div className="box-container-numbered">
          <div className="obvious" onClick={() => setCollapse(!collapse)}>
            <h4 className="study-numbered-subheading">{lang === "English" ? subcategory.name : subcategory.fr_name}
              <span className="collapsed-icon text-big">{collapse ? lang === "English" ? "(Collapse) ▲" : "(Réduire) ▲" : lang === "English" ? "(Expand) ▼" : "(Développer) ▼"}</span>
            </h4>
          </div>
          <Collapse isOpen={collapse} style={{marginBottom: '2rem', padding: '1rem'}}>
            <div>
              <div className="research_heading">
                <h3>
                  {lang === "English" ? subcategory.q1 : subcategory.q1_fr}
                </h3>
              </div>
              <div className="treatment_details_study_container">
                {lang === "English" ? subcategory.a1 : subcategory.a1_fr}
              </div>
            </div>
            
            {/* <div>
              <div className="research_heading">
                <h3>
                  {lang === "English" ? subcategory.q2 : subcategory.q2_fr}
                </h3>
              </div>
              <div className="treatment_details_study_container">
                {lang === "English" ? subcategory.a2 : subcategory.a2_fr}
              </div>
            </div> */}
          </Collapse>
        </div>
      : <>
          <div className='research-subheadings'>
          </div>
        </>

      }
    </React.Fragment>
  )
}

export default Subcategory